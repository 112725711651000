.skills-container{
    max-width: 1200px;
    margin: auto;
    background-color: #191847
}

.col-skills{
    
    height: 100px;
    border-left: 3px solid #7436ee;
    border-radius: 20px;
    background-color: #191847;
    transition: transform .2s;
    padding: 10px 0;
    margin-top: 50px;

}

.col-skills:hover{
    
    transform: scale(1.1);

}

.col-skills p{
    color: white;
    height: 30px;
    padding: 0 10px;
    display: flex;
    align-items: center;
}

@media only screen and (max-device-width: 768px) {

    .skills-container{
        padding: 100px 50px!important;
    }
}
