.about-container {
    max-width: 1200px;
    margin: auto;
    background-color: #191847;
    padding-top: 100px !important;
}

.col-title {
    padding-left: 100px;
}

.col-text {
    padding-right: 100px;
}

.col-text p {
    text-align: justify;
    color: #fff;
}

.col-title-h1 {
    color: #7436ee;
}

.col-title p {
    color: #fff;
    padding: 0;
    margin-left: -5px;
}

.col-title-btn {
    color: white;
    border-style: none;
    background-color: #7436ee;
    transition: transform .2s;
}

.col-title-btn:hover {
    color: white;
    background-color: #7436ee;
    transform: scale(1.1);
}

.col-title-icon{
    display: flex;
    justify-content: space-between;
    padding: 20px 50px 0px 0px;
}

@media only screen and (max-device-width: 768px) {

    .col-title {
        padding: 0 50px;
    }

    .col-text {
        padding: 0 50px;
    }
    .col-text p {
        text-align: start;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .col-text {
        padding-left: 100px;
    }
    .col-title-icon{
        padding: 20px 100px 0px 0px;
    }
}