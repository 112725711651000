.footer-container {
    max-width: 1200px;
    margin: auto;
    height: 160px;
    background-color: #a072ff;
} 
.col-copyright {
    padding-left: 100px;
    
  }

.col-copyright p {
    color: white;
}

.col-gift {
    text-align: end;
    padding-right: 100px;

}



.contact{
    display: flex;
    width: 250px;
    justify-content: space-between;
    
}
@media only screen and (max-device-width: 441px) {

    .col-copyright {
        padding: 0 30px;
    }
    
    .col-gift {
        padding-right: 20px;
        
    }

    .footer-container {
        height: 250px;
    }

}

@media only screen and (min-device-width: 442px) and (max-device-width: 767px) {
    .col-copyright {
        padding: 0 30px;
    }
    
    .col-gift {
        padding-right: 20px;
    }
} 

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
    
}