.work-container {
    max-width: 1200px;
    margin: auto;
    background-color: #191847;
    padding-top: 100px !important;
    padding-bottom: 50px;
}

.work-row {
    padding: 0px 90px;
}

.work-bloc {
    border-left: 3px solid #7436ee;
    border-radius: 20px;
    background-color: #191847;
    padding: 15px;
    margin: 20px 0px;
}

.title {
    text-align: center;
    color: #7436ee;
    margin-bottom: 50px;
}

.work-bloc-title {
    color: white;
    padding: 5px 0px;
}

.img-bloc {
    height: 220px;
    background-image: url("./images/Ecommerce.jpg");
    background-size: cover;
    border-radius: 12px;
}

.img-bloc1 {
    height: 220px;
    background-image: url("./images/export.png");
    background-size: cover;
    border-radius: 12px;
}

.img-bloc2 {
    height: 220px;
    background-image: url("./images/notification.png");
    background-size: cover;
    border-radius: 12px;
}

.img-bloc3 {
    height: 220px;
    background-image: url("./images/portefolio.png");
    background-size: cover;
    border-radius: 12px;
}

.project-desc {
    height: 220px;
    background-color: #7436ee;
    border-radius: 12px;
    padding: 20px 30px;
    transition: transform .2s;
}

.project-desc:hover {
    transform: scale(1.05);
}

.desc-fonct {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: -5px;
    font-size: 12px;
}

.desc-fonct span {
    color: #fff;
}

.desc-icon {
    display: flex;
    justify-content: center;
    gap: 30px;
}

@media only screen and (max-device-width: 768px) {
    .work-row {
        padding: 0 40px;
    }

    .desc-fonct {
        font-size: 12px;
        gap: 10px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {}