.resume-container {
    max-width: 1200px;
    margin: auto;
    background-color: #191847;
    padding-top: 20px !important;
}

.col-edu{
    padding-left: 100px;
}

.col-exp{
    padding-right: 100px;
}
.main-div{
    border-left: 4px Solid #7436ee;
    padding-left: 20px;
    position: relative;
}

.point{
    padding: 0px 10px;
    border-radius: 50%;
    background-color: #7436ee;
}
.first-title {
    color: white;
    margin-bottom: 50px;
    margin-left: -10px;
}

.point-title{
    color: #7436ee;
}

.div-point{
    display: flex;
    gap: 20px;
    position: absolute;
    top: -20px;
    left: -12px;
}

.div-point2{
    display: flex;
    gap: 20px;
    margin-left: -32px;
}

.content{
    padding: 10px 0px 0px 10px;
}

.content div{
    background-color: rgba(255, 255, 255, 0.769);
    color: #191847;
    padding: 15px;
    border-radius: 8px;
    width: 350px;
    height: 130px;
    margin-bottom: 20px;
    transition: transform .2s;
}

.content div:hover{
    transform: scale(1.05);
}

.content h6{
    color: white;
}

@media only screen and (max-device-width: 768px) {
    .col-exp, .col-edu{
        padding-left: 50px;
        padding-right: 50px;
    }
    
    .content div{
        width: auto;
        height: auto;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
    .content div{
        width: 250px;
        height: 110px;
    }
    .col-exp{
        padding-right: 0px;
    }
}