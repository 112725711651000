@keyframes blink {

  0%,
  39%,
  100% {
    cursor: url("cursor.svg"), pointer;
  }

  40%,
  64% {
    cursor: url("cursor1.svg"), pointer;
  }

  65%,
  99% {
    cursor: url("cursor2.svg"), pointer;
  }
}


body {
  background-color: white;
  padding: 0px;
  font-family: 'Offside', sans-serif;
  cursor: url("cursor.svg"), pointer;
  animation: blink 10s infinite;
}
body, html {
  width: 100%;
  overflow-x: hidden;
}

/* *************************************** */