body{
  padding: 0px!important;
}
.navbar-section * {
  margin: 0;
  padding: 0;
}

.banner-container * {
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: auto;
}

.navbar-logo-col {
  height: 100px;
  background-color: #a072ff;
  border-right: 15px solid #7436ee;
}

.logo {
  padding: 30px;
  justify-content: center;
}

.logo-text {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  padding-right: 10px;
  font-size: 1.7rem;
  color: #fff;
}

.vr {
  width: 2px;
  background-color: #fff;
  border-radius: 30%;
}

.navbar-nav-col {
  height: 100px;
  background-color: #191847;
}


.navbar-nav {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.navbar-nav .nav-item {
  flex: 1 0 auto;
}

.nav-link {
  color: white;
}

.nav-link:hover {
  color: #7436ee;
}

.nav {
  padding: 35px;
}

.btn-nav {
  padding: 5px 10px;
  background-color: #7436ee;
  border-radius: 20px;
  transition: transform .2s;
}

.btn-nav:hover {
  color: white;
  background-color: #7436ee;
  transform: scale(1.1);
}

.toggle-nav {
  background-color: #191847;
  margin-right: 25px;
  width: 200px;
  padding: 15px;
  float: right;
  border-radius: 10px;
}

.toggle-navbar {
  position: absolute;
  right: 30px;
  top: 40px;
  border: 0
}

/***************************/

.banner-first-col {
  height: 600px;
  background-color: #a072ff;
  border-right: 15px solid #7436ee;
  padding-top: 170px;
}

.banner-second-col {
  height: 600px;
  background-color: #191847;
  border-bottom: 15px solid #7436ee;
  padding-top: 200px;
}

.banner-first-col-div {
  border-radius: 50%;
  height: 330px;
  width: 340px;
  background-color: #ffffff50;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: -100px;
}

.banner-first-col-img {
  border-radius: 50%;
  height: 320px;
  width: 330px;
}


@media only screen and (max-device-width: 767px) {
  .navbar-logo-col {
    height: 110px;
    background-color: #a072ff;
    border-right: none;
    border-bottom: 15px solid #7436ee;
  }

  .logo {
    justify-content: start;
    padding: 30px;
  }

  .banner-first-col {
    border-right: none;
    padding-top: 130px;
    height: 400px;
  }

  .banner-second-col {
    padding-top: 100px;
    height: 500px;
  }

  .banner-first-col-div {
    float: none;
    margin: auto;
  }

  .nav-item {
    padding: 8px;
  }

  .banner-second-col {
    padding-top: 100px;
    height: 400px;
  }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .navbar-logo-col {
    height: auto;
    background-color: #a072ff;
    border-right: none;
  }

  .navbar-nav-col {
    height: auto;
    background-color: #191847;
  }

  .banner-first-col {
    border-right: none;
    padding-top: 220px;
    height: 500px;
  }

  .banner-second-col {
    padding-top: 100px;
    height: 400px;
  }

  .banner-first-col-div {
    float: none;
    margin: auto;
  }
}